import Vue from "vue";
import Axios from "axios";
import qs from "qs";

const upload = function(file, dir = "", override = false) {
  return new Promise((resolve, reject) => {
    let url = "upload/policy" + (override ? "/override" : "");
    Vue.prototype.$http
      .post(url + "?" + qs.stringify({ dir: dir, filename: file.name }), "")
      .then(res => {
        let formData = new FormData();
        formData.append("name", file.name);
        formData.append("key", res.key);
        formData.append("policy", res.policy);
        formData.append("OSSAccessKeyId", res.accessid);
        formData.append("success_action_status", "200");
        formData.append("callback", res.callback);
        formData.append("signature", res.signature);
        formData.append("file", file);
        Axios.create()
          .post(res.uploadUrl, formData)
          .then(ossRes => {
            if (ossRes.data && ossRes.data.Status === "OK") {
              resolve(res.downloadUrl);
            } else {
              reject("upload failed.");
            }
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
};

export { upload };
