import Vue from "vue";
import Index from "./index.vue";
import vuetify from "../../plugins/vuetify";

const ConfirmConstructor = Vue.extend(Index);

let instance = new ConfirmConstructor({ vuetify });
instance.$mount();
document.body.appendChild(instance.$el);
instance.visible = true;
instance.dom = instance.$el;

const Confirm = function(options) {
  options = options || {};
  instance.setOptions(options);
};

export default Confirm;
