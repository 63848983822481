import Vue from "vue";
import Vuex from "vuex";
import UserPermissionHelper from "../plugins/UserPermissionHelper";

Vue.use(Vuex);
let tabViewIdIncr = 1;
export default new Vuex.Store({
  state: {
    tabViewList: [
      {
        id: tabViewIdIncr,
        path: "/console",
        name: "控制台",
        icon: "nsfont-console",
        closeable: false
      }
    ],
    keepInterval: null,
    XSRFToken: "",
    user: {
      rentId: ""
    },
    companyList: [],
    resourceList: [],
    permissionMap: [],
    queryCondition: {},
    loading: false
  },
  mutations: {
    loading: function(state, loading) {
      state.loading = loading;
    },
    setCompanyList(state, companyList) {
      state.companyList = companyList || [];
    },
    setUser(state, user) {
      if (user) {
        delete user.resourceList;
      }
      state.user = user || {};
    },
    setResourceList(state, resourceList) {
      state.resourceList = resourceList || [];
    },
    setPermissionMap(state, permissionMap) {
      state.permissionMap = permissionMap || {};
    },
    keepSession(state, flag) {
      clearInterval(state.keepInterval);
      if (flag) {
        state.keepInterval = setInterval(() => {
          Vue.prototype.$http.get("auth/passed/keep").then(() => {
          });
        }, 15 * 60 * 1000);
      }
    },
    setXSRFToken(state, token) {
      state.XSRFToken = token;
    },
    setQueryCondition(state, payload) {
      state.queryCondition[payload.path] = payload.condition || {};
    },
    addTabView(state, view) {
      let menu = UserPermissionHelper.fetchMenu(view.path);
      if (!view.name && menu) {
        view.name = menu.name;
      }
      if (!view.icon && menu) {
        view.icon = menu.icon;
      }
      // 如果是从其他页返回列表页，则需要把name改回来
      let listTabView = state.tabViewList.find(tv => tv.path === view.path || tv.path.startsWith(view.path + "/"));
      if (listTabView) {
        listTabView.name = view.name;
        listTabView.path = view.path;
        listTabView.fullPath = view.fullPath;
      } else {
        // 如果是从列表页进入 其他页，则需要改name 改成对应的编辑 新增 等
        view.closeable = true;
        listTabView = state.tabViewList.find(tv => view.path.startsWith(tv.path + "/"));
        if (listTabView) {
          listTabView.name = view.name;
          listTabView.path = view.path;
          listTabView.fullPath = view.fullPath;
        } else {
          view.id = ++tabViewIdIncr;
          state.tabViewList.push(view);
        }
      }
    },
    removeTabView(state, view) {
      if (view.path === "/console") {
        return;
      }
      let index = state.tabViewList.findIndex(tv => tv.path === view.path);
      state.tabViewList.splice(index, 1);
    },
    clearTabView(state) {
      state.tabViewList.splice(1, Infinity);
    }
  },
  actions: {
    getUser(context, _callback) {
      Vue.prototype.$http.get("auth/passed/me").then(data => {
        UserPermissionHelper.generate(data);
        if (_callback) {
          _callback();
        }
      });
    },
    clear(context) {
      context.commit("setUser");
      context.commit("setCompanyList");
      context.commit("setResourceList");
      context.commit("setPermissionMap");

      context.commit("keepSession");
      context.commit("clearTabView");
    }
  },
  modules: {}
});
