<template>
  <media-uploader
    :label="label"
    :src="src"
    :rules="rules"
    accept="image/png,image/jpeg,image/bmp,image/gif,image/webp"
    :capital="label"
    :disabled="disabled"
    :required="required"
    :force-fieldset="forceFieldset"
    :hide-details="hideDetails"
    preview-class="image-uploaded"
    :height="height"
    :reset="reset"
    :remove="remove"
    :base64="base64"
    @success="success"
    @clear="clear"
    @from:store="storeDialog = true"
  >
    <img class="preview-image" :src="src" v-if="src" />
    <v-icon :size="height / 2" color="rgba(0, 0, 0, 0.12)" v-else>nsfont-image-add</v-icon>
  </media-uploader>
</template>

<script>
  import MediaUploader from "./MediaUploader";

  export default {
    name: "ImageUploader",
    data() {
      return {
        src: this.value,
        selected: "",
        loading: false,
        storeDialog: false
      };
    },
    model: {
      prop: "value",
      event: "success"
    },
    props: {
      label: {
        type: String,
        required: false
      },
      value: {
        type: String,
        required: false
      },
      hideDetails: {
        type: Boolean,
        required: false,
        default: false
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      height: {
        type: Number,
        required: false
      },
      reset: {
        type: String,
        required: false
      },
      remove: {
        type: Boolean,
        required: false,
        default: true
      },
      base64: {
        type: Boolean,
        required: false,
        default: false
      },
      forceFieldset: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      MediaUploader
    },
    computed: {},
    watch: {
      value: {
        handler(val) {
          this.src = val;
        }
      }
    },
    methods: {
      success(src) {
        this.src = src;
        this.$emit("success", src);
        this.storeDialog = false;
      },
      clear() {
        this.$emit("clear");
      }
    }
  };
</script>

<style lang="scss">
  .image-uploaded {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyIDIiIGZpbGwtb3BhY2l0eT0iMC4yIj48cmVjdCB4PSIxIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIi8+PHJlY3QgeT0iMSIgd2lkdGg9IjEiIGhlaWdodD0iMSIvPjwvc3ZnPg==)
    center center / 16px 16px repeat rgb(255, 255, 255);
  }

  .preview-image {
    cursor: pointer;
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
