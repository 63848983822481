<template>
  <div class="media-uploader-container">
    <div class="media-uploader" :style="{ minWidth: height + 'px' }" v-if="showFieldset">
      <fieldset :style="{ padding: height / 40 + 'px' }">
        <legend class="label theme--light v-label v-label--active" v-if="label">
          {{ label }}
        </legend>
        <div class="uploader-input">
          <div class="preview" :class="previewClass" @click="selectFile" v-ripple :style="{ height: height + 'px', lineHeight: height + 'px' }">
            <slot />
            <v-progress-linear class="progress-bar" :active="loading" absolute bottom indeterminate></v-progress-linear>
          </div>
        </div>
      </fieldset>
      <input type="file" :accept="accept" class="file-input" ref="file-input" @change="upload" :value="selected" />
      <v-input v-model="src" :rules="computedRules" :hide-details="hideDetails" />
      <div class="uploader-actions" v-if="!disabled">
        <v-btn color="primary" text x-small @click="selectFile"> 选择{{ capital }}</v-btn>
        <v-btn @click="resetSrc" text x-small v-if="reset">重置{{ capital }}</v-btn>
        <v-btn @click="clear" text x-small v-if="remove">删除{{ capital }}</v-btn>
      </div>
    </div>
    <fluid-form-item :required="required" :label="label" v-else>
      <div class="media-uploader" :style="{ minWidth: height + 'px' }">
        <div class="uploader-input no-fieldset">
          <div class="preview" :class="previewClass" @click="selectFile" v-ripple :style="{ height: height + 'px', lineHeight: height + 'px' }">
            <slot />
            <v-progress-linear class="progress-bar" :active="loading" absolute bottom indeterminate></v-progress-linear>
          </div>
        </div>
        <input type="file" :accept="accept" class="file-input" ref="file-input" @change="upload" :value="selected" />
        <v-input v-model="src" :rules="computedRules" :hide-details="hideDetails" />
        <div class="uploader-actions" v-if="!disabled">
          <v-btn color="primary" text x-small @click="selectFile"> 选择{{ capital }}</v-btn>
          <v-btn @click="resetSrc" text x-small v-if="reset">重置{{ capital }}</v-btn>
          <v-btn @click="clear" text x-small v-if="remove">删除{{ capital }}</v-btn>
        </div>
      </div>
    </fluid-form-item>
  </div>
</template>

<script>
  import { upload } from "@/plugins/oss";
  import { required } from "../plugins/rules";

  export default {
    name: "MediaUploader",
    data() {
      return {
        selected: "",
        loading: false
      };
    },
    props: {
      label: {
        type: String,
        required: false
      },
      src: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      hideDetails: {
        type: Boolean,
        required: false,
        default: false
      },
      previewClass: {
        type: String,
        required: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      accept: {
        type: String,
        required: true
      },
      capital: {
        type: String,
        required: true
      },
      height: {
        type: Number,
        required: false,
        default: 180
      },
      reset: {
        type: String,
        required: false
      },
      remove: {
        type: Boolean,
        required: false,
        default: true
      },
      base64: {
        type: Boolean,
        required: false,
        default: false
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      override: {
        type: Boolean,
        required: false,
        default: false
      },
      forceFieldset: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      showFieldset() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm" || this.forceFieldset;
      },
      computedRules() {
        if (this.required) {
          return [v => required(v, `请选择${this.label}`)].concat(this.rules);
        } else {
          return this.rules;
        }
      }
    },
    methods: {
      chooseFromStore() {
        this.$emit("from:store");
      },
      selectFile() {
        if (this.disabled) {
          return false;
        }
        this.$refs["file-input"].click();
      },
      upload(e) {
        let files = e.target.files;
        if (!files || files.length < 1) {
          return;
        }
        let file = files[0];
        this.loading = true;

        if (this.base64) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.$emit("success", reader.result);
            this.selected = "";
            this.loading = false;
          };
        } else {
          upload(file, this.override ? "upload" : "", this.override)
            .then(url => {
              if (this.override) {
                url += "?_t=" + Date.now();
              }
              this.$emit("success", url);
              this.selected = "";
              this.loading = false;
            })
            .catch(err => {
              this.selected = "";
              this.loading = false;
              // console.log(err);
              this.$notify.error(this.capital + "上传失败");
            });
        }
      },
      resetSrc() {
        this.selected = "";
        this.$emit("success", this.reset);
      },
      clear() {
        this.selected = "";
        this.$emit("clear");
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/style/variables";
  .media-uploader-container {
    margin-bottom: 0.5rem;
  }

  .media-uploader {
    position: relative;

    .file-input {
      display: none;
    }

    .v-input__control {
      .v-input__slot {
        display: none;
      }
      .v-messages {
        padding: 0 0.75rem;
      }
    }

    fieldset {
      border: 1px solid $border-color;
      border-radius: 0.125rem;
      overflow: hidden;
      padding: 0.375rem;
      margin-bottom: 0.25rem;
    }

    .uploader-input {
      width: 100%;

      &.no-fieldset {
        border: 1px solid $border-color;
        border-radius: 0.125rem;
        overflow: hidden;
        padding: 0.375rem;
        margin-bottom: 0.25rem;
      }

      .preview {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
        text-align: center;

        .progress-bar {
          top: auto;
        }
      }
    }

    .uploader-actions {
      position: absolute;
      bottom: -0.1875rem;
      right: 0;
    }
  }
</style>
