<template>
  <v-tabs background-color="accent-4" center-active show-arrows :height="36" v-model="activeTab" class="view-tab-list">
    <v-tab v-for="(view, index) in tabViewList" :key="view.id" @click.stop.prevent="switchTab(view)">
      <div class="view-tab">
        <v-icon size="16">{{ view.icon }}</v-icon>
        <span>{{ view.name || "404" }}</span>
        <v-icon size="14" v-if="view.closeable" class="close" @click.stop="closeTab(view, index)">nsfont-tab-close</v-icon>
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
  import { immediatelyPath } from "../../plugins/utils";

  export default {
    name: "ViewTab",
    data() {
      return {
        activeTab: 0
      };
    },
    computed: {
      tabViewList() {
        return this.$store.state.tabViewList;
      }
    },
    watch: {
      "$route.path": function(val) {
        this.changeActiveTab(val);
      }
    },
    created() {
      this.changeActiveTab(immediatelyPath());
    },
    beforeDestroy() {},
    methods: {
      changeActiveTab(path) {
        this.activeTab = this.tabViewList.findIndex(tv => path.startsWith(tv.path + "/") || path === tv.path);
      },
      switchTab(view) {
        if (view.path === this.$route.path) {
          return;
        }
        this.$router.push({
          path: view.fullPath ? view.fullPath : view.path
        });
      },
      closeTab(view, index) {
        this.$store.commit("removeTabView", view);
        if (index === this.activeTab) {
          this.$router.push({
            path: this.tabViewList[this.tabViewList.length - 1].path
          });
        } else {
          this.changeActiveTab(immediatelyPath());
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "src/assets/style/variables";
  .view-tab-list {
    .view-tab {
      display: flex;
      align-items: center;
      span {
        padding-left: 4px;
      }
      .close {
        margin-left: 8px;
      }
    }
  }
</style>
