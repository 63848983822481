import Vue from "vue";

const capitalize = function(str) {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const guid = function(without = false) {
  return (
    s4() + s4() + (without ? "" : "-") + s4() + (without ? "" : "-") + s4() + (without ? "" : "-") + s4() + (without ? "" : "-") + s4() + s4() + s4()
  );
};

const s4 = function() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

const colorHex = function(colorStr) {
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  if (/^(rgb|RGB)/.test(colorStr)) {
    let aColor = colorStr.replace(/(?:||rgb|RGB)*/g, "").split(",");
    let strHex = "#";
    for (let i = 0; i < aColor.length; i++) {
      let hex = Number(aColor[i]).toString(16);
      if (hex === "0") {
        hex += hex;
      }
      strHex += hex;
    }
    if (strHex.length !== 7) {
      strHex = colorStr;
    }
    return strHex;
  } else if (reg.test(colorStr)) {
    let aNum = colorStr.replace(/#/, "").split("");
    if (aNum.length === 6) {
      return colorStr;
    } else if (aNum.length === 3) {
      let numHex = "#";
      for (let i = 0; i < aNum.length; i += 1) {
        numHex += aNum[i] + aNum[i];
      }
      return numHex;
    }
  } else {
    return colorStr;
  }
};

const colorRgba = function(colorStr, opacity) {
  let opacityStr = (opacity || 1) + "";
  if (colorStr.length === 9) {
    let opacityInt = parseInt("0x" + colorStr.substring(7, 9));
    if (opacityInt === 255) {
      opacityStr = "1";
    } else {
      opacityStr = (opacityInt / 255).toFixed(2);
    }
    colorStr = colorStr.substring(0, 7);
  }
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  let sColor = colorStr.toLowerCase();
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = "#";
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    //处理六位的颜色值
    let sColorChange = [];
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    return "rgba(" + sColorChange.join(",") + "," + opacityStr + ")";
  } else {
    return sColor;
  }
};

const immediatelyPath = function() {
  let path = window.location.hash.substring(1);
  let questionMarkIndex = path.indexOf("?");
  if (questionMarkIndex !== -1) {
    path = path.substring(0, questionMarkIndex);
  }
  return path;
};

const setProperties = function(source = {}, target = {}) {
  let prop;
  for (prop in source) {
    let value = source[prop];
    if (typeof value === "object" && !Array.isArray(value)) {
      setProperties(value, target[prop]);
    } else {
      Vue.set(target, prop, value);
    }
  }
};

const dataURLToFile = function(dataurl, filename = "file") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, { type: mime });
};

const ognlResolver = function(data, prop) {
  let index = prop.indexOf(".");
  if (index === -1) {
    return data[prop] === undefined ? "" : data[prop];
  }
  return ognlResolver(data[prop.substring(0, index)], prop.substring(index + 1));
};

export { capitalize, guid, colorHex, colorRgba, immediatelyPath, setProperties, dataURLToFile, ognlResolver };
