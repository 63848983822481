<template>
  <v-submit-dialog
    txt="确认"
    @success="success"
    @fail="$emit('fail')"
    @close="$emit('close')"
    :dialog="dialog"
    icon="nsfont-brigadge"
    title="选择公司"
    :max-width="$root.dialogWidth"
  >
    <v-item-group mandatory v-model="companyIndex">
      <v-container>
        <v-row dense>
          <v-col v-for="b in companyList" :key="b.code" cols="12" md="6">
            <v-item v-slot="{ active, toggle }">
              <v-card :color="active ? 'primary' : ''" :dark="active" class="d-flex align-center" height="64" @click="toggle">
                <div class="flex-grow-1 pl-3 pr-3">
                  <v-avatar size="40">
                    <img :src="b.logo" alt="" />
                  </v-avatar>
                  <span class="ml-2 subtitle-1">{{ b.name }}</span>
                </div>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </v-submit-dialog>
</template>

<script>
  import VSubmitDialog from "../extends/VSubmitDialog";

  export default {
    name: "BrigadeDialog",
    data() {
      return {
        companyIndex: 0
      };
    },
    props: {
      dialog: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      companyList() {
        return this.$store.state.companyList;
      }
    },
    components: {
      VSubmitDialog
    },
    watch: {
      dialog: {
        handler(val) {
          if (val) {
            this.companyIndex = this.companyList.findIndex(b => b.code === this.$store.state.user.rentId);
          }
        }
      }
    },
    methods: {
      success() {
        this.$emit("success", this.companyList[this.companyIndex]);
      }
    }
  };
</script>

<style scoped></style>
