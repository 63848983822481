import Vue from "vue";
import Vuetify, {
  VAppBar,
  VCardText,
  VRow,
  VBtn,
  VRadioGroup,
  VCardTitle,
  VTabs,
  VChip,
  VCardSubtitle,
  VListGroup,
  VAvatar,
  VListItemAvatar,
  VExpandTransition,
  VImg,
  VListItemTitle,
  VToolbar,
  VIcon,
  VListItemSubtitle,
  VRadio,
  VMenu,
  VTextarea,
  VNavigationDrawer,
  VTreeview,
  VListItem,
  VListItemAction,
  VApp,
  VDatePicker,
  VDivider,
  VCheckbox,
  VSelect,
  VMain,
  VCardActions,
  VContainer,
  VCard,
  VCol,
  VForm,
  VSimpleTable,
  VDialog,
  VToolbarTitle,
  VSnackbar,
  VInput,
  VHover,
  VPagination,
  VSpacer,
  VProgressLinear,
  VList,
  VListItemContent,
  VTextField,
  VTab,
  VColorPicker
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
import zhHans from "vuetify/es5/locale/zh-Hans";

Vue.use(Vuetify, {
  components: {
    VAppBar,
    VCardText,
    VRow,
    VBtn,
    VRadioGroup,
    VCardTitle,
    VTabs,
    VChip,
    VCardSubtitle,
    VListGroup,
    VAvatar,
    VListItemAvatar,
    VExpandTransition,
    VImg,
    VListItemTitle,
    VToolbar,
    VIcon,
    VListItemSubtitle,
    VRadio,
    VMenu,
    VTextarea,
    VNavigationDrawer,
    VTreeview,
    VListItem,
    VListItemAction,
    VApp,
    VDatePicker,
    VDivider,
    VCheckbox,
    VSelect,
    VMain,
    VCardActions,
    VContainer,
    VCard,
    VCol,
    VForm,
    VSimpleTable,
    VDialog,
    VToolbarTitle,
    VSnackbar,
    VInput,
    VHover,
    VPagination,
    VSpacer,
    VProgressLinear,
    VList,
    VListItemContent,
    VTextField,
    VTab,
    VColorPicker
  },
  directives: {
    Ripple
  }
});

export default new Vuetify({
  lang: {
    locales: { zhHans },
    current: "zhHans"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#3296ff",
        secondary: "#333333",
        accent: "#3296ff",
        error: "#ff4141",
        info: "#32c8ff",
        success: "#33cc66",
        warning: "#ffc832"
      }
    }
  },
  icons: {
    iconfont: "nsfont",
    values: {
      menu: "nsfont-menu",
      prev: "nsfont-prev",
      next: "nsfont-next",
      dropdown: "nsfont-dropdown",
      sort: "nsfont-sort",
      checkboxOn: "nsfont-cbk-on",
      checkboxOff: "nsfont-cbk-off",
      checkboxIndeterminate: "nsfont-cbk-half",
      clear: "nsfont-clear",
      delete: "nsfont-clear",
      radioOn: "nsfont-radio-on",
      radioOff: "nsfont-radio-off",
      subgroup: "nsfont-subgroup",
      first: "nsfont-first",
      last: "nsfont-last",
      complete: "nsfont-complete",
      success: "nsfont-success",
      delimiter: "nsfont-delimiter"
    }
  }
});
