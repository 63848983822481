import axios from "axios";
import store from "../store";
import router from "../router";
import Vue from "vue";

const Axios = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "/wms/" : "/"
});

Axios.interceptors.request.use(
  config => {
    if (config.method !== "get" && !config.url.startsWith("upload")) {
      store.commit("loading", true);
    }
    config.headers["XSRF-TOKEN"] = store.state.XSRFToken;
    return config;
  },
  error => {
    Vue.prototype.$notify.error("连接超时");
    store.commit("loading", false);
    return Promise.reject(error);
  }
);
//返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
  res => {
    store.commit("loading", false);
    let rep = res.data;
    if (!rep) {
      Vue.prototype.$notify.warning("服务器无响应");
      //走catch
      return Promise.reject("服务器无响应");
    } else {
      if (rep?.type === "application/vnd.ms-excel") {
        return res.data;
      }
      let status = rep.status;
      let message = rep.message;
      let data = rep.data;
      if (status === "success") {
        //走then
        return data;
      } else {
        if (status === "illegal" && data) {
          message = `[${data}]${message}`;
        }
        //走catch
        Vue.prototype.$notify({
          status: status,
          message: message
        });
        //退出到登录页面
        if (status === "invalid") {
          store.dispatch("clear");
          router.push({
            path: "/"
          });
        } else if (status === "missing") {
          // 需要先选择大队
          Vue.prototype.$ep.trigger("missing-company", true);
          return;
        }
        return Promise.reject(rep);
      }
    }
  },
  error => {
    let message = "加载失败";
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          message = "请求错误";
          break;
        case 401:
          message = "未授权，请登录";
          break;
        case 403:
          message = "拒绝访问";
          break;
        case 404:
          message = "请求地址出错";
          break;
        case 408:
          message = "请求超时";
          break;
        case 413:
          message = "文件大小不能超过5M";
          break;
        case 500:
          message = "服务器内部错误";
          break;
        case 501:
          message = "服务未实现";
          break;
        case 502:
          message = "网关错误";
          break;
        case 503:
          message = "服务不可用";
          break;
        case 504:
          message = "网关超时";
          break;
        case 505:
          message = "HTTP版本不受支持";
          break;
        default:
          message = "加载失败";
      }
    }
    Vue.prototype.$notify.error(message);
    store.commit("loading", false);
    return Promise.reject(error);
  }
);
export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, "$http", { value: Axios });
  }
};
