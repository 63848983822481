<template>
  <v-app>
    <v-app-bar app color="#ffffff" elevation="1" v-if="logined">
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon v-if="drawer">nsfont-left-indent</v-icon>
        <v-icon v-else>nsfont-right-indent</v-icon>
      </v-btn>
      <v-btn depressed @click="companyDialog = true" v-if="$store.state.companyList.length > 0">
        <v-icon color="#E53935">nsfont-brigadge</v-icon>
        <span class="ml-2">{{ companyName }}</span>
      </v-btn>
      <div class="" v-else>
        <v-icon color="#E53935">nsfont-brigadge</v-icon>
        <span class="ml-2">{{ companyName }} </span>
      </div>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-menu :close-on-click="true" :offset-y="true">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>nsfont-profile</v-icon>
            </v-btn>
          </template>
          <v-list class="profile-list">
            <v-list-item ripple link @click="showInfoDialog">
              <v-list-item-title>
                <div class="profile-item">
                  <v-icon :size="20">nsfont-personal</v-icon>
                  个人信息
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item ripple link @click="showPassDialog">
              <v-list-item-title>
                <div class="profile-item">
                  <v-icon :size="20">nsfont-lock</v-icon>
                  修改密码
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon @click="logout">
          <v-icon>nsfont-logout</v-icon>
        </v-btn>
      </v-toolbar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app dark color="#191a23" v-if="logined" :width="showModule ? 232 : 200">
      <div class="v-brand">
        <v-avatar size="42">
          <img src="@/assets/logo.png" alt="" />
        </v-avatar>
        <v-toolbar-title class="ml-2 align-center">
          <span class="title">智能仓储</span>
        </v-toolbar-title>
      </div>
      <navigation :show-module="showModule" />
    </v-navigation-drawer>
    <v-container fluid class="page-container">
      <v-main>
        <view-tab v-if="logined" />
        <div class="page-wrapper" :class="{ 'fill-height': !logined }">
          <router-view :key="$store.state.user.rentId" />
        </div>
      </v-main>
    </v-container>
    <pass-dialog :dialog="passDialog" @close="passDialog = false" @success="passDialog = false" />
    <info-dialog :dialog="infoDialog" @close="infoDialog = false" @success="infoSuccess" />
    <company-dialog :dialog="companyDialog" @close="companyDialog = false" @success="companySelected" />
  </v-app>
</template>

<script>
  import Navigation from "./components/app/Navigation";
  import ViewTab from "./components/app/ViewTab";
  import InfoDialog from "./components/app/InfoDialog";
  import PassDialog from "./components/app/PassDialog";
  import CompanyDialog from "./components/app/CompanyDialog";

  export default {
    name: "App",
    components: { Navigation, ViewTab, InfoDialog, PassDialog, CompanyDialog},
    data() {
      return {
        companyDialog: false,
        infoDialog: false,
        passDialog: false,
        drawer: true
      };
    },
    mounted() {},
    computed: {
      logined() {
        return !!this.$store.state.user.username;
      },
      showModule() {
        return this.$store.state.resourceList.length > 3;
      },
      company() {
        return this.$store.state.user.company || {};
      },
      companyName() {
        return this.company.name || "请选择公司";
      }
    },
    created() {
      this.$ep.on("missing-company", () => {
        this.companyDialog = true;
      });
    },
    methods: {
      showInfoDialog() {
        this.infoDialog = true;
      },
      infoSuccess() {
        this.infoDialog = false;
        this.$store.dispatch("getUser");
      },
      showPassDialog() {
        this.passDialog = true;
      },
      companySelected(data) {
        this.$http.post("auth/passed/company", data).then(user => {
          this.$store.commit("setUser", user);
          this.companyDialog = false;
        });
      },
      logout() {
        this.$http.post("logout").then(() => {
          this.$store.dispatch("clear");
          this.$router.push({
            path: "/"
          });
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "assets/style/variables";

  .page-container {
    height: 100%;
    background-color: $bg-color;
    overflow-y: auto;

    .page-wrapper {
      padding-top: 16px;
      padding-bottom: 72px;

      &.fill-height {
        height: 100%;
      }
    }
  }
</style>
