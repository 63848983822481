<template>
  <v-row dense>
    <v-col class="hidden-sm-and-down" cols="12" :md="4" :lg="3" :xl="3">
      <div class="fluid-form-item">
        <span :class="{ required: required }">{{ label }}</span>
      </div>
    </v-col>
    <v-col cols="12" :xs="12" :sm="12" :md="6" :lg="4" :xl="4">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "FluidFormItem",
    data() {
      return {};
    },
    props: {
      label: {
        type: String,
        required: false
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      innerLabel() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm" ? this.label : "";
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "src/assets/style/variables";

  .fluid-form-item {
    height: 32px;
    line-height: 32px;
    text-align: right;
    padding: 0 16px;

    span.required:before {
      content: " * ";
      color: $color-error;
    }
  }
</style>
