<template>
  <v-dialog persistent v-model="submitDialog" scrollable :max-width="maxWidth" :fullscreen="fullscreen">
    <v-card>
      <v-card-title>
        <v-icon class="v-dialog-title-icon" :size="20">{{ icon }}</v-icon>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon small @click="fullscreen = !fullscreen">
          <v-icon :size="20">{{ fullscreen ? "nsfont-unfull" : "nsfont-full" }}</v-icon>
        </v-btn>
        <v-btn icon small @click="$emit('close')" class="dialog-close-btn">
          <v-icon :size="20">nsfont-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <slot></slot>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> 取消</v-btn>
        <submit-button
          :txt="txt"
          :text="true"
          :url="url"
          :method="method"
          :disabled="disabled"
          :post-data="postData"
          :notify="notify"
          @success="$emit('success')"
          @fail="$emit('fail')"
        ></submit-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import SubmitButton from "@/components/SubmitButton";

  export default {
    name: "VSubmitDialog",
    data() {
      return {
        valid: true,
        fullscreen: false,
        submitDialog: this.dialog
      };
    },
    watch: {
      dialog() {
        this.submitDialog = this.dialog;
      }
    },
    props: {
      url: {
        type: String,
        required: false
      },
      dialog: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      txt: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      method: {
        type: String,
        required: false
      },
      notify: {
        type: String,
        required: false
      },
      maxWidth: {
        type: Number,
        required: true
      },
      postData: {
        type: [Object, Array],
        required: false
      }
    },
    components: { SubmitButton }
  };
</script>
