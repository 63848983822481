import store from "../store";

class UserPermissionHelper {
  constructor() {
    this.menuMap = {};
  }

  /**
   * 生成权限树，权限map
   * @param data
   */
  generate(data = {}) {
    let user = data.user;
    //将list转换成数结构，resourceList已经有顺序了(先序)
    let resourceList = user.resourceList || [];
    //按钮级别权限
    let permissionList = [];
    let resourceTree = [];
    let module, folder, menu;
    resourceList.forEach(resource => {
      if (resource.type === "module") {
        resource.children = [];
        resourceTree.push(resource);
        module = resource;
      } else if (resource.type === "folder") {
        resource.children = [];
        resource.expanded = false;
        module.children.push(resource);
        folder = resource;
      } else if (resource.type === "menu") {
        resource.children = [];
        try {
          let parts = resource.path.split("/");
          //模块的path即是第一个菜单
          if (folder.children.length === 0) {
            //vpath 用于计算面包屑
            parts.pop();
            folder.vpath = parts.join("/");
            if (!module.vpath) {
              parts.pop();
              module.vpath = parts.join("/");
              module.path = resource.path;
            }
          }
          folder.children.push(resource);
          menu = resource;
          this.menuMap[menu.path] = menu;
        } catch (e) {
          //防止测试时发生意外
          console.warn(e);
        }
      } else {
        menu.children.push(resource);
        permissionList.push(resource.code);
      }
    });
    store.commit("setUser", user);
    store.commit("setCompanyList", data.companyList);
    store.commit("setResourceList", resourceTree);
    //权限标识是以冒号分割的 module:folder:menu:button
    //将前三段取出
    let permissionMap = {};
    permissionList.forEach(permission => {
      let index = permission.lastIndexOf(":");
      let key = permission.substring(0, index);
      let value = permission.substring(index + 1);
      if (!permissionMap[key]) {
        permissionMap[key] = {};
      }
      permissionMap[key][value] = true;
    });
    store.commit("setPermissionMap", permissionMap);
    // 保持session
    store.commit("keepSession", true);
  }

  /**
   * 抓取菜单
   * @param path
   */
  fetchMenu(path) {
    let menu = this.menuMap[path];
    if (menu) {
      return menu;
    } else {
      let index = path.lastIndexOf("/");
      path = path.substring(0, index);
      return this.menuMap[path];
    }
  }
}

export default new UserPermissionHelper();
