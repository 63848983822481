<template>
  <fluid-form-item :label="label">
    <v-checkbox v-model="modelValue" dense :true-value="trueValue" :false-value="falseValue" @change="valueChange"></v-checkbox>
    <p class="help-block" v-if="tips">{{ tips }}</p>
  </fluid-form-item>
</template>

<script>
  import FluidFormItem from "./FluidFormItem";

  export default {
    name: "MRadio",
    data() {
      return {
        modelValue: this.value
      };
    },
    model: {
      prop: "value",
      event: "change"
    },
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: [String, Number],
        required: true
      },
      trueValue: {
        type: String,
        required: false,
        default: "1"
      },
      falseValue: {
        type: String,
        required: false,
        default: "0"
      },
      disabled: {
        type: Boolean,
        required: false
      },
      tips: {
        type: String,
        required: false
      }
    },
    watch: {
      value: {
        handler(val) {
          this.modelValue = val;
        },
        immediate: true
      }
    },
    components: { FluidFormItem },
    methods: {
      valueChange() {
        this.$emit("change", this.modelValue);
      }
    }
  };
</script>

<style scoped></style>
