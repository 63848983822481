export default [
  // {
  //   path: "/repository/repository",
  //   name: "repository",
  //   component: () => import("../../views/system/repository/Repository.vue")
  // },
  // {
  //   path: "/repository/repository/edit",
  //   component: () => import("../../views/system/repository/RepositoryEdit.vue"),
  //   meta: { name: "仓库", _dynamic_: true }
  // },
  {
    path: "/repository/material",
    component: () => import("../../views/system/repository/material/Material.vue"),
  },
  {
    path: "/repository/material/edit",
    component: () => import("../../views/system/repository/material/MaterialEdit.vue"),
    meta: { name: "物资库存", _dynamic_: true }
  },
  {
    path: "/repository/check",
    component: () => import("../../views/system/repository/check/InventoryCheck.vue"),
  },
  {
    path: "/repository/check/edit",
    component: () => import("../../views/system/repository/check/InventoryCheckEdit.vue"),
    meta: { name: "盘点单", _dynamic_: true }
  },
  {
    path: "/repository/history",
    component: () => import("../../views/system/repository/history/HistoryLog.vue"),
    meta: { name: "出入库记录" }
  },
  {
    path: "/repository/history-stat/",
    component: () => import("../../views/system/repository/history/stat/HistoryStat.vue"),
    meta: { name: "出入库盘点" }
  },
  {
    path: "/repository/grant",
    component: () => import("../../views/system/repository/grant/GrantMaterial.vue")
  },
  {
    path: "/repository/grant/edit",
    component: () => import("../../views/system/repository/grant/GrantMaterialEdit.vue"),
    meta: { name: "发放", _dynamic_: true }
  }
];
