export default [
  {
    path: "/system/flow/flow",
    name: "flow",
    component: () => import("../../views/system/flow/Flow.vue")
  },
  {
    path: "/system/flow/flow/edit",
    component: () => import("../../views/system/flow/FlowEdit.vue"),
    meta: { name: "方案", _dynamic_: true }
  },
  {
    path: "/system/flow/audit",
    name: "audit",
    component: () => import("../../views/system/flow/audit/Audit.vue")
  }
];
