<template>
  <fluid-form-item :required="required" :label="label">
    <v-textarea
      outlined
      dense
      :rows="rows"
      v-model="modelValue"
      :rules="computedRules"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :label="innerLabel"
      @input="valueInput"
      @click="$emit('click', $event)"
    >
    </v-textarea>
    <p class="help-block" v-if="tips">{{ tips }}</p>
  </fluid-form-item>
</template>
<script>
  import FluidFormItem from "./FluidFormItem";
  import { required } from "../../../plugins/rules";

  export default {
    name: "MTextField",
    data() {
      return {
        modelValue: this.value
      };
    },
    model: {
      prop: "value",
      event: "input"
    },
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: [String, Number],
        required: true
      },
      type: {
        type: String,
        required: false,
        default: "text"
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      disabled: {
        type: Boolean,
        required: false
      },
      readonly: {
        type: Boolean,
        required: false
      },
      required: {
        type: Boolean,
        required: false
      },
      maxlength: {
        type: Number,
        required: false
      },
      rows: {
        type: Number,
        required: false,
        default: 3
      },
      tips: {
        type: String,
        required: false
      }
    },
    watch: {
      value: {
        handler(val) {
          this.modelValue = val;
        },
        immediate: true
      }
    },
    computed: {
      innerLabel() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm" ? this.label : "";
      },
      computedRules() {
        if (this.required) {
          return [v => required(v, `请输入${this.label}`)].concat(this.rules);
        } else {
          return this.rules;
        }
      }
    },
    components: { FluidFormItem },
    created() {},
    methods: {
      valueInput() {
        this.$emit("input", this.modelValue);
      }
    }
  };
</script>
