<template>
  <fluid-form-item :required="required" :label="label">
    <v-menu v-model="menu" :max-width="250" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="value"
          :hide-details="hideDetails"
          :disabled="disabled"
          :clearable="clearable"
          @click:clear="$emit('click:clear')"
          :label="innerLabel"
          outlined
          dense
          readonly
          :rules="computedRules"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <div class="datetime-picker">
        <v-date-picker v-model="date" no-title :width="250" :first-day-of-week="1" v-if="showDatePicker"></v-date-picker>
        <div class="date-picker-footer">
          <div class="time-picker" v-if="showTimePicker">
            <v-select v-model="hour" :items="hourList" dense hide-details append-icon=""></v-select>
            <div class="time-separator">:</div>
            <v-select v-model="minute" :items="minuteList" dense hide-details append-icon=""></v-select>
            <div class="time-separator">:</div>
            <v-select v-model="second" :items="secondList" dense hide-details append-icon=""></v-select>
          </div>
          <div class="action-buttons">
            <v-btn text small @click="clear">清空</v-btn>
            <v-btn text color="primary" small @click="ok">确定</v-btn>
          </div>
        </div>
      </div>
    </v-menu>
    <p class="help-block" v-if="tips">{{ tips }}</p>
  </fluid-form-item>
</template>

<script>
  import { format, parse } from "date-fns";
  import { required } from "../../../plugins/rules";

  const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
  const DEFAULT_TIME_FORMAT = "HH:mm:ss";

  export default {
    name: "MDatetimrPicker",
    data() {
      return {
        menu: false,
        date: format(new Date(), this.dateFormat),
        hour: "00",
        minute: "00",
        second: "00",
        hourList: [],
        minuteList: [],
        secondList: []
      };
    },
    model: {
      prop: "value",
      event: "input"
    },
    props: {
      value: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        required: false
      },
      disabled: {
        type: Boolean
      },
      hideDetails: {
        type: Boolean
      },
      clearable: {
        type: Boolean
      },
      rules: {
        type: Array,
        default: () => {
          return [];
        }
      },
      dateFormat: {
        type: String,
        default: DEFAULT_DATE_FORMAT
      },
      timeFormat: {
        type: String,
        default: DEFAULT_TIME_FORMAT
      },
      type: {
        type: String,
        required: false,
        default: "datetime"
      },
      tips: {
        type: String,
        required: false
      }
    },
    created() {
      for (let i = 0; i < 24; i++) {
        this.hourList.push((i < 10 ? "0" : "") + i);
      }
      for (let i = 0; i < 60; i++) {
        this.minuteList.push((i < 10 ? "0" : "") + i);
        this.secondList.push((i < 10 ? "0" : "") + i);
      }
    },
    computed: {
      innerLabel() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm" ? this.label : "";
      },
      computedRules() {
        if (this.required) {
          return [v => required(v, `请输入${this.label}`)].concat(this.rules);
        } else {
          return this.rules;
        }
      },
      showDatePicker() {
        return this.type === "datetime" || this.type === "date";
      },
      showTimePicker() {
        return this.type === "datetime" || this.type === "time";
      },
      selectedTime() {
        return `${this.hour}:${this.minute}:${this.second}`;
      },
      selectedDatetime() {
        if (this.type === "datetime") {
          return this.date + " " + this.selectedTime;
        } else if (this.type === "date") {
          return this.date;
        } else {
          return this.selectedTime;
        }
      }
    },
    watch: {
      value: {
        handler(val) {
          if (!val) {
            this.date = format(new Date(), this.dateFormat);
            this.hour = "00";
            this.minute = "00";
            this.second = "00";
          } else {
            let date;
            if (this.type === "datetime") {
              date = parse(val, this.dateFormat + " " + this.timeFormat, new Date());
            } else if (this.type === "date") {
              date = parse(val, this.dateFormat, new Date());
            } else {
              date = parse(val, this.timeFormat, new Date());
            }
            this.date = format(date, this.dateFormat);
            this.hour = format(date, "HH");
            this.minute = format(date, "mm");
            this.second = format(date, "ss");
          }
        },
        immediate: true
      }
    },
    components: {},
    methods: {
      clear() {
        this.$emit("input", "");
        this.menu = false;
      },
      ok() {
        this.$emit("input", this.selectedDatetime);
        this.menu = false;
      }
    }
  };
</script>

<style lang="scss">
  @import "src/assets/style/variables";
  .datetime-picker {
    background-color: $color-white;

    .date-picker-footer {
      border-top: 1px solid $border-color;
      padding: 12px 8px;
      display: flex;
      .time-picker {
        flex: 1;
        display: flex;
        align-items: center;
        .time-separator {
          padding: 0 2px;
        }
        .v-select__selection {
          padding-left: 4px;
        }
      }
      .action-buttons {
        display: flex;
        align-items: flex-end;
      }
    }
  }
</style>
