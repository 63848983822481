import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";

import systemAuthRoutes from "./system/auth";
import inventoryRouter from "./inventory/InventoryRouter";
import materialRoutes from "./material/MaterialRouter";
import systemCompanyRoutes from "./system/company";
import systemFlowRoutes from "./system/flow";
import systemRepositoryRoutes from "./system/repository";
import { immediatelyPath } from "@/plugins/utils";

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/console",
    name: "Console",
    component: () => import("../views/Console.vue")
  }
];

const routes = baseRoutes
  .concat(systemAuthRoutes)
  .concat(systemCompanyRoutes)
  .concat(materialRoutes)
  .concat(inventoryRouter)
  .concat(systemFlowRoutes)
  .concat(systemRepositoryRoutes)
  .concat([
    {
      path: "*",
      name: "NotFound",
      component: () => import("../views/NotFound.vue")
    }
  ]);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/" && to.path !== "/console") {
    let name = to.meta.name;
    if (to.meta["_dynamic_"]) {
      if (to.query.identify) {
        name += "编辑";
      } else {
        name += "新增";
      }
    }
    store.commit("addTabView", {
      path: to.path,
      name: name,
      icon: to.meta.icon,
      fullPath: to.fullPath
    });
  }
  next();
});
router.afterEach(() => {
  let path = immediatelyPath();
  if (path === "/" && store.state.user.username) {
    router.push({
      path: "/console"
    });
  }
});

export default router;
