export default [
  // {
  //   path: "/material/supplier",
  //   component: () => import("../../views/material/Supplier.vue")
  // },
  // {
  //   path: "/material/supplier/edit",
  //   component: () => import("../../views/material/SupplierEdit.vue"),
  //   meta: { name: "供应商", _dynamic_: true }
  // },
  {
    path: "/material/type",
    component: () => import("../../views/material/MaterialType.vue")
  },
  {
    path: "/material/type/edit",
    component: () => import("../../views/material/MaterialTypeEdit.vue"),
    meta: { name: "分类", _dynamic_: true }
  },
  {
    path: "/material/muster",
    component: () => import("../../views/material/Material.vue")
  },
  {
    path: "/material/muster/edit",
    component: () => import("../../views/material/MaterialEdit.vue"),
    meta: { name: "物资", _dynamic_: true }
  },
  {
    path: "/material/unit",
    component: () => import("../../views/material/MaterialUnit.vue")
  },
  {
    path: "/material/unit/edit",
    component: () => import("../../views/material/MaterialUnitEdit.vue"),
    meta: { name: "单位", _dynamic_: true }
  },
  {
    path: "/material/purchase",
    name: "purchase",
    component: () => import("../../views/purchase/Purchase.vue")
  },
  {
    path: "/material/purchase/edit",
    component: () => import("../../views/purchase/PurchaseEdit.vue"),
    meta: { name: "采购单", _dynamic_: true }
  },
  {
    path: "/material/acquire",
    name: "acquire",
    component: () => import("../../views/acquire/Acquire.vue")
  },
  {
    path: "/material/acquire/edit",
    component: () => import("../../views/acquire/AcquireEdit.vue"),
    meta: { name: "申请单", _dynamic_: true }
  },
  {
    path: "/material/access/plan",
    name: "accessPlan",
    component: () => import("../../views/purchase/AccessPlan.vue")
  }
];
