import { guid } from "./utils";

export default class ResizeEvent {
  constructor() {
    this.events = {};
    this.init();
  }

  init() {
    window.onresize = () => {
      let key;
      for (key in this.events) {
        this.events[key](window.innerWidth, window.innerHeight);
      }
    };
  }

  on(_callback) {
    let key = guid();
    this.events[key] = _callback;
    return key;
  }

  off(key) {
    if (!this.events[key]) {
      console.warn("resize key not found. ");
    }
    delete this.events[key];
  }
}
