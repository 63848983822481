const required = function(v, message) {
  return !!v || (!isNaN(v) && v === 0) || message;
};

const equalTo = function(model, v, e, message) {
  return v === model[e] || message;
};

const alphanum_ = function(v, message) {
  return /^[a-zA-Z0-9_]*$/.test(v) || message;
};

const min = function(v, m, message) {
  return !v || (!isNaN(v) && v >= m) || message;
};

const max = function(v, m, message) {
  return !v || (!isNaN(v) && v <= m) || message;
};

const range = function(v, min, max, message) {
  return !v || (!isNaN(v) && v >= min && v <= max) || message;
};

const length = function(v = "", min = 0, max = Infinity, message) {
  return (v.length >= min && v.length <= max) || message;
};

const notEq = function(v, c, message) {
  return v !== c || message;
};

export { required, equalTo, alphanum_, min, max, range, length, notEq };
