<template>
  <fluid-form-item :required="required" :label="label">
    <v-select
      outlined
      dense
      :height="32"
      :items="items"
      v-model="modelValue"
      :label="innerLabel"
      :item-text="itemText"
      :item-value="itemValue"
      :return-object="returnObject"
      :rules="computedRules"
      :clearable="clearable"
      :disabled="disabled"
      :multiple="multiple"
      :chips="chips"
      @focus="$emit('focus', $event)"
      @change="valueChange"
    ></v-select>
    <p class="help-block" v-if="tips">{{ tips }}</p>
  </fluid-form-item>
</template>
<script>
  import FluidFormItem from "./FluidFormItem";
  import { required } from "../../../plugins/rules";

  export default {
    name: "MSelect",
    data() {
      return {
        modelValue: this.value
      };
    },
    model: {
      prop: "value",
      event: "change"
    },
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      itemText: {
        type: String,
        required: false
      },
      itemValue: {
        type: String,
        required: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      disabled: {
        type: Boolean,
        required: false
      },
      required: {
        type: Boolean,
        required: false
      },
      returnObject: {
        type: Boolean,
        required: false
      },
      clearable: {
        type: Boolean,
        required: false
      },
      multiple: {
        type: Boolean,
        required: false
      },
      chips: {
        type: Boolean,
        required: false
      },
      tips: {
        type: String,
        required: false
      }
    },
    watch: {
      value: {
        handler(val) {
          this.modelValue = val;
        },
        immediate: true
      }
    },
    computed: {
      innerLabel() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm" ? this.label : "";
      },
      computedRules() {
        if (this.required) {
          return [v => required(v, `请选择${this.label}`)].concat(this.rules);
        } else {
          return this.rules;
        }
      }
    },
    components: { FluidFormItem },
    created() {},
    methods: {
      valueChange() {
        this.$emit("change", this.modelValue);
      }
    }
  };
</script>
