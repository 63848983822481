export default class Dict {
  constructor() {
    this.map = {};
    this.init();
  }

  init() {
    this.map["http_method"] = [
      { group: "http_method", name: "GET", code: "GET", value: "GET" },
      { group: "http_method", name: "POST", code: "POST", value: "POST" },
      { group: "http_method", name: "PUT", code: "PUT", value: "PUT" },
      { group: "http_method", name: "DELETE", code: "DELETE", value: "DELETE" }
    ];
    this.map["http_status"] = [
      { group: "http_status", name: "SUCCESS", code: "SUCCESS", value: "SUCCESS" },
      { group: "http_status", name: "ERROR", code: "ERROR", value: "ERROR" }
    ];
  }

  load(dictList = []) {
    dictList.forEach(dict => {
      let group = dict.group;
      let dataList = this.map[group];
      if (!dataList) {
        this.map[group] = dataList = [];
      }
      dict.value = dict.code;
      dataList.push(dict);
    });
  }

  codeOf(group, name) {
    let dataList = this.map[group];
    if (dataList && dataList.length > 0) {
      for (let i = 0; i < dataList.length; i++) {
        let dict = dataList[i];
        if (dict.name == name) {
          return dict.code;
        }
      }
    }
    return "";
  }

  nameOf(group, code) {
    let dataList = this.map[group];
    if (dataList && dataList.length > 0) {
      for (let i = 0; i < dataList.length; i++) {
        let dict = dataList[i];
        if (dict.code == code) {
          return dict.name;
        }
      }
    }
    return "";
  }
}
