<template>
  <v-row dense class="v-editor">
    <v-col class="hidden-sm-and-down" cols="12" :md="4" :lg="3" :xl="3">
      <div class="v-editor__form-item">
        <span :class="{ required: required }">{{ label }}</span>
      </div>
    </v-col>
    <v-col cols="12" :xs="12" :sm="12" :md="7" :lg="8" :xl="8">
      <div class="v-related-table">
        <v-input v-model="dataList" :rules="tableRules">
          <v-simple-table class="table-border" dense :fixed-header="fixedHeader" :height="fixedTableHeight">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="th" v-for="header in headerList" :key="header.value">{{ header.text }}</th>
                  <th class="th" v-if="!disabled">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="data.id">
                  <td v-for="header in headerList" :key="header.value">
                    <slot :name="header.value" :item="data" :index="index"></slot>
                  </td>
                  <td v-if="!disabled">
                    <slot name="actions" :item="data"></slot>
                    <v-btn text small @click="$emit('remove', index, dataList)" :style="{ marginTop: '-' + removeOffset + 'px' }">删除</v-btn>
                    <template v-if="sortable">
                      <v-btn text small @click="moveUp(index)" :style="{ marginTop: '-' + removeOffset + 'px' }" :disabled="index === 0"
                        ><span class="nsfont nsfont-move-up"></span
                      ></v-btn>
                      <v-btn
                        text
                        small
                        @click="moveDown(index)"
                        :style="{ marginTop: '-' + removeOffset + 'px' }"
                        :disabled="index === dataList.length - 1"
                        ><span class="nsfont nsfont-move-down"></span
                      ></v-btn>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-input>
        <div class="table-actions" v-if="!disabled">
          <v-btn color="primary" text small @click="$emit('add', dataList)"> 新增{{ capital }}</v-btn>
          <slot name="buttons"></slot>
        </div>
      </div>
      <p class="help-block" v-if="tips">{{ tips }}</p>
    </v-col>
  </v-row>
</template>

<script>
  import { required } from "@/plugins/rules";
  export default {
    name: "VRelatedTable",
    props: {
      label: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        required: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      headerList: {
        type: Array,
        required: true
      },
      dataList: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      capital: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      removeOffset: {
        type: Number,
        required: false,
        default: 24
      },
      sortable: {
        type: Boolean,
        required: false,
        default: true
      },
      tips: {
        type: String,
        required: false
      },
      fixedHeader: {
        type: Boolean,
        required: false,
        default: false
      },
      fixedTableHeight: {
        type: Number,
        required: false
      },
      tableDense: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      tableRules() {
        if (this.required) {
          return [v => required(v, `请配置${this.label}`), v => v?.length > 0 || `请配置${this.label}`].concat(this.rules);
        } else {
          return this.rules;
        }
      }
    },
    methods: {
      moveDown(index) {
        if (index === this.dataList.length - 1) {
          return;
        }
        let temp = this.dataList[index + 1];
        this.$set(this.dataList, index + 1, this.dataList[index]);
        this.$set(this.dataList, index, temp);
        this.$emit("sorted");
      },
      moveUp(index) {
        if (index === 0) {
          return;
        }
        let temp = this.dataList[index - 1];
        this.$set(this.dataList, index - 1, this.dataList[index]);
        this.$set(this.dataList, index, temp);
        this.$emit("sorted");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-related-table {
    position: relative;

    .table-border {
      .th {
        height: 2.5rem !important;
        text-align: center;
      }

      .td {
        padding: 1rem !important;
      }
    }

    .table-actions {
      position: absolute;
      bottom: -0.5rem;
      right: 0;
    }
  }
</style>
