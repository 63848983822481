<template>
  <v-snackbar v-model="snackbar" top vertical :color="color" :multi-line="true" :absolute="true" :timeout="5000">
    <div class="snackbar-content">{{ message }}</div>
    <template v-slot:action="{ attrs }">
      <v-btn class="snackbar-close-btn" text v-bind="attrs" @click="snackbar = false">
        关闭
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: "notification",
    data() {
      return {
        snackbar: false,
        status: "",
        message: "",
        onClose: () => {}
      };
    },
    computed: {
      color() {
        let color;
        if (this.status === "success") {
          color = "bg-success";
        } else if (this.status === "fail") {
          color = "bg-error";
        } else if (this.status === "error") {
          color = "bg-error";
        } else if (this.status === "warning") {
          color = "bg-warning";
        } else if (this.status === "forbidden") {
          color = "bg-warning";
        } else if (this.status === "missing") {
          color = "bg-warning";
        } else if (this.status === "invalid") {
          color = "bg-error";
        } else {
          color = "bg-warning";
        }
        return color;
      }
    },
    watch: {
      snackbar(currVal) {
        if (!currVal && typeof this.onClose === "function") {
          this.onClose();
        }
      }
    },
    methods: {
      setOptions(options) {
        this.snackbar = true;
        this.status = options.status;
        this.message = options.message;
        this.onClose = options.onClose;
      }
    }
  };
</script>

<style scoped lang="scss">
  .snackbar-content {
    word-break: break-all;
  }

  .snackbar-close-btn {
    margin-right: 16px !important;
  }
</style>
