<template>
  <fluid-form-item :required="required" :label="label">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field outlined dense v-model="modelText" :rules="computedRules" readonly :label="innerLabel" v-bind="attrs" v-on="on"> </v-text-field>
      </template>
      <div class="combo-tree">
        <v-treeview
          dense
          v-model="modelValue"
          :items="treeData"
          activatable
          :item-key="itemKey"
          :item-text="itemText"
          hoverable
          :open="['__TOP__']"
          @update:active="valueChange"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item.children && item.children.length > 0">
              {{ open ? "nsfont-folder-open" : "nsfont-folder" }}
            </v-icon>
            <v-icon v-else>
<!--              nsfont-file-->
            </v-icon>
          </template>
        </v-treeview>
      </div>
    </v-menu>
    <p class="help-block" v-if="tips">{{ tips }}</p>
  </fluid-form-item>
</template>
<script>
  import FluidFormItem from "./FluidFormItem";
  import { required } from "../../../plugins/rules";
  import LTT from "../../../plugins/list2tree";

  export default {
    name: "MComboTree",
    data() {
      return {
        modelValue: [this.value],
        modelText: ""
      };
    },
    model: {
      prop: "value",
      event: "change"
    },
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      itemKey: {
        type: String,
        required: false
      },
      itemText: {
        type: String,
        required: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      required: {
        type: Boolean,
        required: false
      },
      tips: {
        type: String,
        required: false
      }
    },
    watch: {
      value: {
        handler(val) {
          this.modelValue = [val];
          let model = this.findChildren(this.items, val);
          this.modelText = model ? model[this.itemText] : "";
        },
        immediate: true
      }
    },
    computed: {
      treeData() {
        let ltt = new LTT(this.items, {
          key_sort: "sort",
          key_id: "code",
          key_parent: "pcode",
          key_child: "children"
        });
        return ltt.GetTree();
      },
      innerLabel() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm" ? this.label : "";
      },
      computedRules() {
        if (this.required) {
          return [v => required(v, `请选择${this.label}`)].concat(this.rules);
        } else {
          return this.rules;
        }
      }
    },
    components: { FluidFormItem },
    created() {},
    methods: {
      valueChange(val) {
        this.modelValue = val;
        this.$emit("change", this.modelValue[0]);
      },
      findChildren(items, val) {
        for (let item of items) {
          if (item[this.itemKey] === val) {
            return item;
          }
          if (item.children && item.children.length > 0) {
            const found = this.findChildren(item.children, val);
            if (found) {
              return found;
            }
          }
        }
        return null; // 如果没有找到，返回 null
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "src/assets/style/variables";
  .combo-tree {
    background-color: $color-white;
    padding: 16px 0;
  }
</style>
