<template>
  <div class="navigation-container">
    <ul class="module-list elevation-1" v-if="showModule">
      <li v-for="module in moduleList" :key="module.code" :class="{ active: currentModule.code === module.code }" @click="currentModule = module">
        <v-icon> {{ module.icon }}</v-icon>
        <span>{{ module.name }}</span>
      </li>
    </ul>
    <div class="folder-container" :class="{ 'fill-width': !showModule }">
      <v-list dense class="folder-list">
        <template v-for="folder in folderList">
          <v-list-group class="folder" :key="folder.code" v-model="folder.expanded" :prepend-icon="folder.icon" append-icon="nsfont-arrow-down">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ folder.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              class="menu"
              :class="{ active: menu.path === $route.path || $route.path.startsWith(menu.path + '/') }"
              v-for="menu in folder.children"
              :key="menu.code"
              link
              :href="'#' + menu.path"
            >
              <v-list-item-action class="menu-icon">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ menu.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
  import { immediatelyPath } from "../../plugins/utils";

  export default {
    name: "Navigation",
    data() {
      return {
        currentModule: {
          code: ""
        },
        folderList: []
      };
    },
    props: {
      showModule: Boolean
    },
    watch: {
      currentModule: {
        handler(val) {
          let module = this.moduleList.find(m => m.code === val.code);
          if (!this.showModule) {
            return;
          }
          this.folderList = module ? module.children : [];
          this.folderList.forEach((folder, index) => {
            let path = immediatelyPath();
            if (path.startsWith(folder.vpath) || (path === "/console" && index === 0)) {
              folder.expanded = true;
            }
          });
        },
        deep: true,
        immediate: true
      },
      "$route.path": {
        handler(toPath, fromPath) {
          if (this.folderList.length > 0 && fromPath === "/" && toPath === "/console") {
            this.folderList[0].expanded = true;
          }
        }
      }
    },
    computed: {
      moduleList() {
        return this.$store.state.resourceList;
      }
    },
    created() {
      if (this.showModule) {
        this.initModule();
      } else {
        this.initFolderList();
      }
    },
    methods: {
      initModule() {
        if (this.moduleList.length > 0) {
          let path = immediatelyPath();
          if (path === "/console") {
            this.currentModule = this.moduleList[0];
          } else {
            this.currentModule = this.moduleList.find(m => {
              return path.startsWith(m.vpath);
            }) || { code: "" };
          }
        }
      },
      initFolderList() {
        let moduleList = this.moduleList;
        moduleList.forEach((module, mIndex) => {
          (module.children || []).forEach((folder, fIndex) => {
            let path = immediatelyPath();
            if (path.startsWith(folder.vpath) || (path === "/console" && mIndex === 0 && fIndex === 0)) {
              folder.expanded = true;
            }
            this.folderList.push(folder);
          });
        });
      }
    }
  };
</script>

<style lang="scss">
  @import "src/assets/style/variables";

  .navigation-container {
    display: flex;
    width: 100%;
    height: calc(100% - 64px);

    .module-list {
      width: 64px;
      height: 100%;
      background: #13131a;
      li {
        padding: 8px 0;
        text-align: center;
        cursor: pointer;
        color: #dddddd;
        .v-icon {
          color: #dddddd;
        }
        &:hover,
        &:focus {
          color: #ffffff;
          .v-icon {
            color: #ffffff;
          }
        }
        &.active {
          color: #ffffff;
          background: #191a23;
          .v-icon {
            color: #ffffff;
          }
        }
        span {
          display: block;
          font-size: 13px;
        }
      }
    }

    .folder-container {
      height: 100%;
      width: calc(100% - 64px);
      overflow-y: auto;

      &.fill-width {
        width: 100%;
      }

      .folder-list {
        .folder {
          .v-list-group__header__prepend-icon {
            i {
              font-size: 18px;
            }
          }
          .v-list-group__header__append-icon {
            i {
              font-size: 16px;
            }
          }

          .menu {
            &.active {
              color: #ffffff !important;
              background-color: $color-primary;
              .v-icon {
                color: #ffffff !important;
              }
            }
            .menu-icon {
              i {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
</style>
