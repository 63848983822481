import Vue from "vue";
import Index from "./index.vue";

let NotificationConstructor = Vue.extend(Index);
let instance = new NotificationConstructor();
instance.$mount();
document.body.appendChild(instance.$el);
instance.visible = true;
instance.dom = instance.$el;

const Notification = function(options) {
  options = options || {};
  instance.setOptions(options);
};

["success", "fail", "error", "warning", "forbidden", "missing", "invalid"].forEach(status => {
  Notification[status] = (options, onClose) => {
    if (typeof options === "string") {
      options = {
        message: options,
        onClose: onClose
      };
    }
    options.status = status;
    return Notification(options);
  };
});

export default Notification;
