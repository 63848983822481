<template>
  <v-submit-dialog
    txt="确认"
    url="system/auth/users/password"
    :post-data="user"
    @success="$emit('success')"
    @fail="$emit('fail')"
    @close="$emit('close')"
    :dialog="dialog"
    method="post"
    icon="nsfont-lock"
    title="修改密码"
    :max-width="$root.dialogWidth"
    notify="修改成功"
  >
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field type="password" v-model="user.oldPass" label="当前密码" outlined dense :rules="rules.oldPass"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field type="password" v-model="user.password" label="新密码" outlined dense :rules="rules.password"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field type="password" v-model="user.surePass" label="确认密码" outlined dense :rules="rules.surePass"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-submit-dialog>
</template>

<script>
  import VSubmitDialog from "../extends/VSubmitDialog";
  import { equalTo, required } from "@/plugins/rules";

  export default {
    name: "PassDialog",
    data() {
      return {
        user: {
          password: "",
          oldPass: "",
          surePass: ""
        },
        rules: {
          password: [v => required(v, "请输入新密码")],
          oldPass: [v => required(v, "请输入当前密码")],
          surePass: [v => equalTo(this.user, v, "password", "密码输入不一致")]
        }
      };
    },
    props: {
      dialog: {
        type: Boolean,
        required: true
      }
    },
    components: {
      VSubmitDialog
    }
  };
</script>

<style scoped></style>
