export default [
  {
    path: "/system/company",
    component: () => import("../../views/system/company/Company.vue")
  },
  {
    path: "/system/company/edit",
    component: () => import("../../views/system/company/CompanyEdit.vue"),
    meta: { name: "公司", _dynamic_: true }
  }
];
