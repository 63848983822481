<template>
  <v-row dense class="v-editor">
    <v-col class="hidden-sm-and-down" cols="12" :md="4" :lg="3" :xl="3">
      <div class="v-editor__form-item">
        <span :class="{ required: required }">{{ label }}</span>
      </div>
    </v-col>
    <v-col cols="12" :xs="12" :sm="12" :md="7" :lg="8" :xl="8">
      <editor v-model="content" :init="init"></editor>
      <v-text-field v-model="txt" :rules="computedRules" class="rule-input" />
      <p class="help-block" v-if="tips">{{ tips }}</p>
    </v-col>
  </v-row>
</template>

<script>
  import { upload } from "@/plugins/oss";
  import tinymce from "tinymce/tinymce";
  import Editor from "@tinymce/tinymce-vue";
  import "tinymce/themes/silver";
  import "tinymce/plugins/image"; // 插入上传图片插件
  import "tinymce/plugins/media"; // 插入视频插件
  import "tinymce/plugins/table"; // 插入表格插件
  import "tinymce/plugins/lists"; // 列表插件
  import "tinymce/plugins/paste";
  import "tinymce/plugins/code";
  import "tinymce/plugins/link";
  import "tinymce/plugins/advlist";
  import "tinymce/plugins/autolink";
  import "tinymce/plugins/charmap";
  import "tinymce/plugins/print";
  import "tinymce/plugins/preview";
  import "tinymce/plugins/anchor";
  import "tinymce/plugins/searchreplace";
  import "tinymce/plugins/visualblocks";
  import "tinymce/plugins/fullscreen";
  import "tinymce/plugins/insertdatetime";
  import "tinymce/plugins/help";
  import "tinymce/plugins/autosave";
  import "tinymce/plugins/hr";
  import "tinymce/plugins/wordcount";
  import { dataURLToFile } from "../../../plugins/utils";
  import { required } from "../../../plugins/rules";
  export default {
    name: "MEditor",
    data() {
      return {
        content: this.txt,
        init: {}
      };
    },
    props: {
      label: {
        type: String,
        required: true
      },
      txt: {
        type: String,
        required: true
      },
      watermark: {
        type: String,
        required: false
      },
      required: {
        type: Boolean,
        required: false
      },
      tips: {
        type: String,
        required: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      }
    },
    computed: {
      computedRules() {
        if (this.required) {
          return [v => required(v, `请输入${this.label}`)].concat(this.rules);
        } else {
          return this.rules;
        }
      }
    },
    watch: {
      txt(currVal) {
        this.content = currVal;
      },
      content(currVal) {
        this.$emit("input", currVal);
      }
    },
    components: { Editor },
    created() {
      const that = this;
      that.init = {
        image_dimensions: false,
        media_dimensions: false,
        language_url: "https://static.newstartjx.com/static/wms/app/static/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "https://static.newstartjx.com/static/wms/app/static/tinymce/skins/ui/oxide",
        content_css: "https://static.newstartjx.com/static/wms/app/static/tinymce/skins/content/default/content.css",
        height: 500,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen hr autosave",
          "insertdatetime media table paste help wordcount"
        ],
        fontsize_formats: "10px 12px 14px 16px 18px 20px 24px 32px 36px 40px 48px",
        toolbar:
          "undo redo | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | formatselect | fontselect | fontsizeselect | bold italic forecolor backcolor | image media link | txtheader txtbutton | restoredraft removeformat | help",
        branding: false,
        menubar: true,
        autosave_interval: "5s",
        file_picker_types: "media",
        file_picker_callback: (callback, value, meta) => {
          if (meta.filetype === "media") {
            let input = document.createElement("input");
            input.setAttribute("type", "file");
            input.onchange = function() {
              let file = this.files[0];
              upload(file)
                .then(url => {
                  callback(url, { title: file.name, width: "100%", height: "auto" });
                })
                .catch(err => {
                  // console.log(err);
                  that.$notify.error("视频上传失败");
                });
            };
            //触发点击
            input.click();
          }
        },
        images_upload_handler: (blobInfo, success, failure) => {
          if (that.watermark && !blobInfo.filename().endsWith(".gif")) {
            let image = new Image();
            image.src = "data:image/png;base64," + blobInfo.base64();
            image.onload = () => {
              let canvas = document.createElement("canvas");
              canvas.width = image.naturalWidth;
              canvas.height = image.naturalHeight;
              let context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, canvas.width, canvas.height);
              context.textBaseline = "middle";
              context.font = 'bold 32px "微软雅黑"';
              context.fillStyle = "rgba(255,255,255,0.5)";
              context.fillText(that.watermark, 30, 120);
              context.strokeStyle = "rgba(0,0,0,0.5)";
              context.strokeText(that.watermark, 30, 120);
              let file = dataURLToFile(canvas.toDataURL("image/png", 0.9));
              this.uploadImage(file, success, failure);
            };
          } else {
            this.uploadImage(blobInfo.blob(), success, failure);
          }
        }
      };
    },
    mounted() {
      tinymce.init({});
    },
    methods: {
      uploadImage(file, success, failure) {
        upload(file)
          .then(url => {
            success(url);
          })
          .catch(err => {
            // console.log(err);
            this.$notify.error("图片上传失败");
            failure();
          });
      }
    }
  };
</script>

<style lang="scss">
  @import "src/assets/style/variables";

  .v-editor {
    .v-editor__form-item {
      height: 32px;
      line-height: 32px;
      text-align: right;
      padding: 0 16px;

      span.required:before {
        content: " * ";
        color: $color-error;
      }
    }
    .rule-input {
      margin-top: 0.25rem;
      padding: 0 0.75rem;

      .v-input__slot {
        display: none;
      }
    }
  }
</style>
