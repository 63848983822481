<template>
  <v-btn :color="color" dense depressed :class="customClass" @click="submit" :loading="$store.state.loading" :text="text" :disabled="disabled">
    {{ txt }}
  </v-btn>
</template>
<script>
  export default {
    data() {
      return {};
    },
    props: {
      url: {
        type: String,
        required: false
      },
      validations: {
        type: Object,
        required: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      text: {
        type: Boolean,
        default: false
      },
      method: {
        type: String,
        required: false,
        default: "post",
        validator: function(value) {
          return ["get", "post", "put", "delete"].indexOf(value) > -1;
        }
      },
      txt: {
        type: String,
        required: true
      },
      color: {
        type: String,
        required: false,
        default: "primary"
      },
      postData: {
        type: [Object, Array],
        required: false
      },
      form: {
        type: String,
        required: false,
        default: "form"
      },
      customClass: {
        type: String,
        required: false
      },
      notify: {
        type: String,
        required: false
      }
    },
    methods: {
      submit() {
        //一直往上层找，直到找到form为止，省去了使用mounted去获取
        let parent = this.$parent;
        while (parent && !parent.$refs[this.form]) {
          parent = parent.$parent;
        }
        if (parent && parent.$refs[this.form] && !parent.$refs[this.form].validate()) {
          this.$nextTick(() => {
            let message = "请完善数据";
            let errors = document.querySelectorAll(".error--text .v-messages__message");
            if (errors && errors.length > 0) {
              if (errors[0].innerText) {
                message = errors[0].innerText;
              }
            }
            this.$notify.warning(message);
          });
          return;
        }
        // 有url就提交，没有就只做校验处理
        if (this.url) {
          this.$http
            .request({
              method: this.method,
              url: this.url,
              data: this.postData
            })
            .then(data => {
              if (this.notify) {
                this.$notify.success(this.notify);
              }
              this.$emit("success", data);
            })
            .catch(err => {
              this.$emit("fail", err);
            });
        } else {
          this.$emit("success");
        }
      }
    }
  };
</script>
<style scoped lang="scss">
  .fixed-wrapper {
    display: block;

    &.active {
      position: fixed;
      bottom: 1.5rem;
    }
  }
</style>
