export default [
  {
    path: "/inventory/shipment",
    component: () => import("../../views/inventory/ShipmentOrder.vue")
  },
  {
    path: "/inventory/shipment/edit",
    component: () => import("../../views/inventory/ShipmentOrderEdit.vue"),
    meta: { name: "出库", _dynamic_: true }
  },
  {
    path: "/inventory/receipt",
    component: () => import("../../views/inventory/ReceiptOrder.vue")
  },
  {
    path: "/inventory/receipt/edit",
    component: () => import("../../views/inventory/ReceiptOrderEdit.vue"),
    meta: { name: "入库", _dynamic_: true }
  },
  {
    path: "/inventory/scrap",
    component: () => import("../../views/inventory/ScrapOrder.vue")
  },
  {
    path: "/inventory/scrap/edit",
    component: () => import("../../views/inventory/ScrapOrderEdit.vue"),
    meta: { name: "报废单", _dynamic_: true }
  }
];
