export default [
  {
    path: "/system/auth/user",
    component: () => import("../../views/system/auth/User.vue")
  },
  {
    path: "/system/auth/user/edit",
    component: () => import("../../views/system/auth/UserEdit.vue"),
    meta: { name: "用户", _dynamic_: true }
  },
  {
    path: "/system/auth/role",
    component: () => import("../../views/system/auth/Role.vue")
  },
  {
    path: "/system/auth/role/edit",
    component: () => import("../../views/system/auth/RoleEdit.vue"),
    meta: { name: "角色", _dynamic_: true }
  },
  {
    path: "/system/auth/dept",
    component: () => import("../../views/system/auth/Dept.vue")
  },
  {
    path: "/system/auth/dept/edit",
    component: () => import("../../views/system/auth/DeptEdit.vue"),
    meta: { name: "岗位", _dynamic_: true }
  },
  {
    path: "/system/auth/position",
    component: () => import("../../views/system/auth/position.vue")
  },
  {
    path: "/system/auth/position/edit",
    component: () => import("../../views/system/auth/positionEdit.vue"),
    meta: { name: "职务", _dynamic_: true }
  },
  {
    path: "/system/auth/resource",
    component: () => import("../../views/system/auth/Resource.vue")
  },
  {
    path: "/system/auth/resource/edit",
    component: () => import("../../views/system/auth/ResourceEdit.vue"),
    meta: { name: "资源", _dynamic_: true }
  },
  {
    path: "/system/auth/log",
    component: () => import("../../views/system/auth/Log.vue")
  }
];
