<template>
  <v-dialog v-model="dialog" persistent max-width="320" attach="body">
    <v-card>
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="confirm-message">
          {{ message }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="cancel">取消</v-btn>
        <v-btn color="#3296ff" text @click="ok" :loading="loading">确认</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "notification",
    data() {
      return {
        dialog: false,
        loading: false,
        title: "",
        message: "",
        onCancel: () => {},
        onCallback: () => {}
      };
    },
    methods: {
      cancel() {
        this.dialog = false;
        this.onCancel();
      },
      ok() {
        this.loading = true;
        new Promise((resolve, reject) => {
          this.onCallback(resolve, reject);
        })
          .then(() => {
            this.dialog = false;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      setOptions(options) {
        this.dialog = true;
        this.title = options.title || "";
        this.message = options.message;
        this.onCancel = options.cancel || (() => {});
        this.onCallback = options.callback || (() => {});
      }
    }
  };
</script>

<style scoped lang="scss">
  .confirm-message {
    margin-top: 1.25rem;
    word-break: break-all;
  }
</style>
